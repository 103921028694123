import { styled } from "@mui/system";

const CardsSharedStyled = styled("div")(() => ({
  marginLeft: "20px",
  marginTop: "20px",
  "& .MuiTypography-root": {
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& textarea": {
    width: "800px",
    height: "100px",
  },
}));

export default CardsSharedStyled;
