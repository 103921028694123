export const cryptoCurrencies: ICryptoList = {
  AAVE: {
    fullName: "Aave",
    currentPrice: null,
    ticker: "aave",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2021,
  },
  ADA: {
    fullName: "Cardano",
    currentPrice: "",
    ticker: "ada",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2018,
  },
  ALGO: {
    fullName: "Algorand",
    currentPrice: "",
    ticker: "algo",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2020,
  },
  BNB: {
    fullName: "Binance",
    currentPrice: "",
    ticker: "bnb",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2018,
  },
  BTC: {
    fullName: "Bitcoin",
    currentPrice: "",
    ticker: "btc",
    marketCap: null,
    change: null,
    exchange: "Coinbase",
    toSymbol: "USD",
    startYear: 2012,
  },
  BCH: {
    fullName: "Bitcoin Cash",
    currentPrice: null,
    ticker: "bch",
    marketCap: null,
    change: null,
    exchange: "Coinbase",
    toSymbol: "USDT",
    startYear: 2018,
  },
  DAI: {
    fullName: "Dai",
    currentPrice: null,
    ticker: "dai",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2018,
  },
  DOGE: {
    fullName: "Doge",
    currentPrice: null,
    ticker: "doge",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2014,
  },
  DOT: {
    fullName: "Polkadot",
    currentPrice: null,
    ticker: "dot",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2022,
  },
  ETH: {
    fullName: "Ethereum",
    currentPrice: null,
    ticker: "eth",
    marketCap: null,
    change: null,
    exchange: "Coinbase",
    toSymbol: "USD",
    startYear: 2016,
  },
  LUNA: {
    fullName: "Terra Classic",
    currentPrice: null,
    ticker: "luna",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2022,
  },
  LINK: {
    fullName: "Chainlink",
    currentPrice: null,
    ticker: "link",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2018,
  },
  LTC: {
    fullName: "Litecoin",
    currentPrice: null,
    ticker: "ltc",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2014,
  },
  MATIC: {
    fullName: "Polygon",
    currentPrice: null,
    ticker: "matic",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
  },
  SHIB: {
    fullName: "Shibu",
    currentPrice: null,
    ticker: "shib",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2021,
  },
  SOL: {
    fullName: "Solana",
    currentPrice: null,
    ticker: "sol",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2021,
  },
  UNI: {
    fullName: "Uniswap",
    currentPrice: null,
    ticker: "uni",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2021,
  },
  XLM: {
    fullName: "Stellar Lumens",
    currentPrice: null,
    ticker: "xlm",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2015,
  },
  XRP: {
    fullName: "XRP",
    currentPrice: null,
    ticker: "xrp",
    marketCap: null,
    change: null,
    exchange: "Binance",
    toSymbol: "USDT",
    startYear: 2015,
  },
  XTZ: {
    fullName: "Tezos",
    currentPrice: null,
    ticker: "xtz",
    marketCap: null,
    change: null,
    exchange: "Coinbase",
    toSymbol: "USDT",
    startYear: 2019,
  },
};

export default cryptoCurrencies;
