const defaultRoiCard1: IRoiCard = {
  title: "Sample Bitcoin Roi Calculator",
  ticker: "BTC",
  fullName: "Bitcoin",
  investment: "$10,000",
  purchasePrice: "8057",
  purchasePriceWhen: "10/20/2000",
  sellPrice: "16837.85",
  sellPriceWhen: "10/20/2022",
  useCurrentPricePurchase: "false",
  useCurrentPriceSell: "false",
  useCustomPrice: "true",
  revertedDate: Date.now(),
};

const defaultRoiCard2: IRoiCard = {
  title: "Sample Ethereum Roi Calculator",
  ticker: "ETH",
  fullName: "Ethereum",
  investment: "$10,000",
  purchasePrice: "200",
  purchasePriceWhen: "10/20/2000",
  sellPrice: "1231.35",
  sellPriceWhen: "10/20/2022",
  useCustomSell: "true",
  useCustomPrice: "true",
  useCurrentPricePurchase: "false",
  useCurrentPriceSell: "false",
  revertedDate: Date.now(),
};

const defaultCards = {
  defaultRoiCard1,
  defaultRoiCard2,
};

export default defaultCards;
