import { useState, useRef, useEffect } from "react";
import { Typography } from "@mui/material";
import CardsSharedStyled from "./CardsShareStyled";
import { getShareUrl } from "utils/utilities";

interface CardsShardProps {
  roiCards: IRoiCard[];
}

const CardsShare: React.FC<CardsShardProps> = ({ roiCards }) => {
  const [showShareLink, setShowShareLink] = useState(false);
  const textRef = useRef<HTMLTextAreaElement | null>(null);
  const [textCopied, setTextCopied] = useState("Copy");
  const [textToCopy, setTextToCopy] = useState("");

  const onClick = () => {
    setShowShareLink(true);
  };

  async function copyTextToClipboard() {
    const text = textRef.current?.value || "";
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      setTextCopied("Copied");
    }
  }

  useEffect(() => {
    setTextToCopy(getShareUrl(roiCards));
  }, [roiCards]);

  useEffect(() => {
    if (textCopied === "Copied") {
      window.setTimeout(() => {
        setTextCopied("Copy");
        setShowShareLink(false);
      }, 2000);
    }
  }, [textCopied]);

  return (
    <CardsSharedStyled>
      {showShareLink ? (
        <div>
          <Typography onClick={copyTextToClipboard}>{textCopied}</Typography>
          <textarea ref={textRef} value={textToCopy} />
        </div>
      ) : (
        <Typography onClick={onClick}>Share these ROI projections</Typography>
      )}
    </CardsSharedStyled>
  );
};

export default CardsShare;
